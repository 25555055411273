// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

/* * {
    color: gray;
} */
/*.centerBody {
    background-color: #b5e1de;
}*/

.headerBorder {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-bottom: 5px solid;
}
.headerColor {
  background: rgb(195,28,28);
  background: linear-gradient(132deg, rgba(195,28,28,0.8324463574492297) 0%, rgba(204,43,43,0.8632586823792017) 44%, rgba(166,56,173,0.9024743686537114) 86%, rgba(72,0,72,0.8800654050682774) 100%);
}
.footer {
    background-color: rgba(43, 61, 76, 0.7);
    border-top: none;
}

.pgb .step {
    text-align: center;
    position: relative;
}
.pgb h2 {
    font-size: 1.3rem;
}
.pgb .step p {
    position: absolute;
    height: 60px;
    width: 100%;
    text-align: center;
    display: block;
    z-index: 3;
    color: #fff;
    font-size: 160%;
    line-height: 55px;
    opacity: 0.7;
}
.pgb .active.step p {
    opacity: 1;
    font-weight: 600;
}
.pgb .img-circle {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #9e9e9e;
    border: 4px solid #fff;
}
.pgb .complete .img-circle {
    background-color: #4caf50;
}
.pgb .active .img-circle {
    background-color: #ff9800;
}
.pgb .step .img-circle:before {
    content: "";
    display: block;
    background: #9e9e9e;
    height: 4px;
    width: 50%;
    position: absolute;
    bottom: 50%;
    left: 0;
    z-index: -1;
    margin-right: 24px;
}
.pgb .step .img-circle:after {
    content: "";
    display: block;
    background: #9e9e9e;
    height: 4px;
    width: 50%;
    position: absolute;
    bottom: 50%;
    left: 50%;
    z-index: -1;
}
.pgb .step.active .img-circle:after {
    background: #9e9e9e;
}

.pgb .step.complete .img-circle:after,
.pgb .step.active .img-circle:before {
    background: #4caf50;
}

.pgb .step:last-of-type .img-circle:after,
.pgb .step:first-of-type .img-circle:before {
    display: none;
}

/* START LOGIN */

.loginContainer {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 70% 1fr;
    padding: 0 !important;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1);
}

.loginColum {
    padding: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.sideLogin {
    align-items: center;
    background-image: url("../assets/images/911_login.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.sideLogin::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.action {
    background-color: #0e484b;
    color: #fff;
    padding: 1em 3em;
    margin: 2em auto 4em;
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.4s;
}

button.action:hover {
    opacity: 0.7;
}

@media (max-width: 980px) {
    .loginContainer {
        grid-template-columns: 60% 1fr;
    }

    .loginColum {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
}

@media (max-width: 800px) {
    .loginContainer {
        grid-template-columns: 55% 1fr;
    }

    .sideLogin .main-msg {
        font-size: 2.7rem;
    }
}

@media (max-width: 700px) {
    .loginContainer {
        grid-template-columns: 1fr;
    }

    .sideLogin {
        display: none;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    p {
        text-align: center;
    }
}

/* END LOGIN */

//START LOADING
.rootLoading {
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1051;
    background-color: rgba(0, 0, 0, 0.42);
    overflow: none;
}

.containerLoading {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

.loaderLoading {
    border: 8px solid #f6f6f6;
    border-radius: 50%;
    border-top: 8px solid #343875;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
//ENDLOADING


.text-red{
    color:red;
}

.rbc-month-row {
    min-height: 50px;
}

.rbc-day-bg.selected{
        background-color: darkcyan;
}

.navBarHeader{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 470px)  {
    .nameDisplay{
        display: none;
    }

}

.rbc-event{
    .rbc-event-label{
        display: none;
    }
    .rbc-event-content{
        padding: 8px;
        color: white;
    }
    &.available{
        >.rbc-event-content{
            color: black !important;
        }
        background-color: white !important;

    }
    background-color: darkred !important;
}

.rbc-month-view{
    .rbc-event-content{
        padding: 0px;
    }
}

.rbc-time-view{
    .rbc-event-content{
        padding: 0px;
    }
}

/*START Generals */
.boxShadow{
    box-shadow: 0px 2px 10px 5px rgb(89 89 89 / 50%) !important;
}


.interFont {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 900;
    font-size: 1.1rem;
    color: gray !important;
}
.borderRadius{
    border-radius: 15px;
}
.wrapper{
    background-color: #f1f1f1;
}
.titleText{
    font-size: 1.6rem !important;
}

.pText{
    font-size: 0.7rem !important;
}

.boxShadowRed{
    box-shadow: 0px 2px 10px 5px #ff8b8b !important;
    background-color: red;
    .title-card-big {color: white !important;}

}
.boxShadowYellow{
    box-shadow: 0px 2px 10px 5px #F5FFA2 !important;
    background-color: yellow;
    .title-card-big {color: black !important;}
}
.boxShadowGreen{
    box-shadow: 0px 2px 10px 5px #AAFFA2 !important;
    background-color: #4caf50;
    .title-card-big {color: #f1f1f1 !important;}
}
.boxShadowLightBlue{
    box-shadow: 0px 2px 10px 5px #9FD1FF !important;
    background-color:deepskyblue;
    .title-card-big {color: white !important;}
}

//text
.textShadowRed{
    color:  #ff8b8b !important;
}
.textShadowYellow{
    color: #F5FFA2 !important;
}
.textShadowGreen{
    color:  #AAFFA2 !important;
}
.textShadowLightBlue{
    color:  #9FD1FF !important;
}
/*END Generals */



/* START COLORS */
  .sidebar-color{
    background: rgb(75,80,162);
    background: linear-gradient(180deg, rgba(49, 53, 129, 0.95) 0%, rgba(57, 59, 73, 0.98)  100% );

  }
  .footer-color{
    background: rgb(195,28,28);
    background: linear-gradient(132deg, rgba(195,28,28,0.8324463574492297) 0%, rgba(204,43,43,0.8632586823792017) 44%, rgba(166,56,173,0.9024743686537114) 86%, rgba(72,0,72,0.8800654050682774) 100%);

  }

/*END COLORS */



/* START CALENDAR */
.rbc-event-content {
    color: black;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
}

.rbc-event-label {
    color: black;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
}

.rbc-event {
    padding: 0;
    display: flex;
    justify-content: center;
}
.centerTurnsHeaderContainer{
    display: flex;
}
.centerTurnsHeaderButton{
    display: flex;
    align-items: center;
}
.turnsHeaderSelect{
    height: 40px !important;
}

@media (max-width:1200) {

}

/* END CALENDAR */

.container-lg{
    margin: 0 !important;
    max-width: 100%;
}
.text-danger{
    color: red !important;
}

.cardPadding{
    padding: 5%;
}
.hrMargin{
    margin-left: 20%;
    margin-right: 20%;
}

.hrColor{
    color:#838282;
    opacity: .7;
}
.marginButton{
    margin-left: 2%;
}

.cursorPointer{
    cursor: pointer;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .addCommentText{
    font-size:.9rem !important ;
  }
  .addCommentThAndTd{
    width: 20%;
  }

  .swal2-styled.swal2-confirm{
    border: solid 1px red !important;
    background-color: #f1f1f1 !important;
    color: gray !important;
  }

  /*START DESPATCH FILTER */

  .commonFilterAndButtonContainer{
    display: flex;
    flex-direction: column;
    padding: 1% 0;
  }
  .commonButtonsFilter{
    display: flex;
    justify-content: space-between;
  }
  .commonFilterContainer{
    display: flex;
  }
  /*END DESPATCH FILTER */

  .mouse-hand{cursor: pointer; };

